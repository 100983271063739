<template>
    <el-container class="home-container">
        <!--头部-->
        <el-header>
            <div>
                <span>湖北省高校运动员信息注册系统</span>
            </div>
            <el-button type="info" @click="logout" >退出</el-button>
        </el-header>
        <el-container>
            <!--侧边区域-->
            <el-aside width="200px">
                <el-menu background-color="#545c64" text-color="#fff" active-text-color="#409EFF" unique-opened router >
                    <el-menu-item index="/edu/classcate">
                        <template slot="title">
                        <span>高校信息录入</span>
                        </template>
                    </el-menu-item>
                    <el-menu-item index="/edu/coach">
                        <template slot="title">
                        <span>注册运动员信息</span>
                        </template>
                    </el-menu-item>
                </el-menu>
            </el-aside>
            <!--主体区域-->
            <el-main>
                <router-view>
                </router-view>
            </el-main>
        </el-container>
    </el-container>
</template>

<script>
export default {
  methods: {
    logout () {
        window.sessionStorage.removeItem('token')
        this.$router.push('/login')
    }
  }
}

</script>

<style lang="less" scoped>
.home-container{
    height: 100%;
}
.el-header{
    background-color: #2B2B2B;
    display: flex;
    justify-content: space-between;
    padding-left: 0;
    align-items: center;
    color:#F7F709;
    font-size:20px;

    > div{
        display: flex;
        align-items: center;
        img {
        height: 5%;
        width: 5%;
        border-radius: 50%;
        background-color: #eee;
      }
        span{
            margin-left: 15px;
        }
    }
}
.el-aside{
    background-color: #545c64;
    .el-menu{
        border-right: none;
    }
}
.el-main{
    background-color: #EEEEEE;
}

</style>
