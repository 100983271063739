<template>
    <div>
        <!--导航-->
        <el-breadcrumb separator="/">
            <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>高校信息录入</el-breadcrumb-item>

        </el-breadcrumb>
        <!--卡片-->
        <el-card class="box-card">
            <el-row :gutter="20">
                <!--搜索-->
                <el-col :span="8">
                    <el-input placeholder="请输入学校名" v-model="queryInfo.school">
                        <el-button slot="append" icon="el-icon-search" @click="getClassList()"></el-button>
                    </el-input>
                </el-col>
                <!--添加课程-->
                <el-col :span="4">
                    <el-button type="primary" @click="addDialogVisible = true">添加学校</el-button>
                </el-col>
            </el-row>
            <!--课程列表-->
            <el-table :data="classList" border stripe>
                <el-table-column type="index"></el-table-column>
                <el-table-column label="学校名" prop="school"></el-table-column>
                <el-table-column label="学校邀请码" prop="code"></el-table-column>
                <el-table-column label="管理员密码" prop="secret"></el-table-column>

                <el-table-column label="操作" width="180px">
                    <template slot-scope="scope">
                    <el-tooltip effect="dark" content="编辑" placement="top" :enterable="false">
                        <el-button type="primary" icon="el-icon-edit" size="mini" @click="showEditDialog(scope.row.id)"></el-button>
                    </el-tooltip>
                    <el-tooltip effect="dark" content="删除" placement="top" :enterable="false">
                        <el-button type="danger" icon="el-icon-delete" size="mini" @click="removeClassById(scope.row.id)"></el-button>
                    </el-tooltip>
                </template>
                </el-table-column>
            </el-table>
            <!--分页-->
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="queryInfo.pagenum"
            :page-sizes="[5, 10, 15, 20]" :page-size="queryInfo.pagesize" layout="total, sizes, prev, pager, next, jumper" :total="this.total">
            </el-pagination>
        </el-card>
        <!--添加对话框-->
        <el-dialog title="添加学校" :visible.sync="addDialogVisible" width="60%" :close-on-click-modal="false" @close="addDialogclosed">
            <!--内容-->
            <el-form :model="addForm" :rules="addFormRules" ref="addFormRef" label-width="160px">
                <el-form-item label="学校名称" prop="school">
                    <el-input v-model="addForm.school"></el-input>
                </el-form-item>
                <el-form-item label="学校邀请码" prop="code">
                    <el-input v-model="addForm.code"></el-input>
                </el-form-item>
                <el-form-item label="学校管理员密码" prop="secret">
                    <el-input v-model.number="addForm.secret"></el-input>
                </el-form-item>
            </el-form>
            <!--底部-->
            <span slot="footer" class="dialog-footer">
                <el-button @click="addDialogVisible=false">取 消</el-button>
                <el-button type="primary" @click="addClass">确 定</el-button>
            </span>
        </el-dialog>
        <!--修改对话框-->
        <el-dialog title="修改学校" :visible.sync="editDialogVisible" width="60%" :close-on-click-modal="false" >
            <!--修改内容-->
            <el-form :model="editForm" :rules="addFormRules" ref="editFormRef" label-width="160px">
                <el-form-item label="学校名称" prop="school">
                    <el-input v-model="editForm.school"></el-input>
                </el-form-item>
                <el-form-item label="学校邀请码" prop="code">
                    <el-input v-model="editForm.code"></el-input>
                </el-form-item>
                <el-form-item label="学校管理员密码" prop="secret">
                    <el-input v-model.number="editForm.secret"></el-input>
                </el-form-item>
            </el-form>
            <!--底部-->
            <span slot="footer" class = "dialog-footer">
                <el-button @click="editDialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="editClassInfo">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
export default {
  data () {
    return {

      /* 分页请求数据 */
      queryInfo: {
        query: '',
        pagenum: 1,
        pagesize: 10,
        school:''
      },
      /* 教练列表 */
      classList: [],
      /* 数据总数 */
      total: 0,
      /* 控制添加修改显示 */
      addDialogVisible: false,
      editDialogVisible: false,
      editForm: {},
      /* 添加班级表单 */
      addForm: {
          school: '',
          code: '',
          secret: '',

      },
      /* 添加修改规则 */
      addFormRules: {
          school: [
              { required: true, message: '请输入学校名称', trigger: 'blur' }
          ],
          code: [
              { required: true, message: '请输入学校邀请码', trigger: 'blur' }
          ],
          secret: [
              { required: true, message: '请输入管理员密码', trigger: 'blur' }
          ],
      }
    }
  },
  created () {
    this.getClassList()
  },
  methods: {
    /* 获取课程数据 */
    async getClassList () {
        const { data: res } = await this.$http.get('classes/' + this.queryInfo.pagenum + '/' + this.queryInfo.pagesize + '?school=' + this.queryInfo.school)
        if (res.flag !== true) {
            return this.$message.error('获取数据失败！请重新登录')
        }

        this.$message.success('获取学校列表成功!')
        this.classList = res.data
        this.total = parseInt(res.msg)

    },
    /* 处理页面大小 */
    handleSizeChange (newSize) {
        this.queryInfo.pagesize = newSize
        this.getClassList()
    },
    /* 处理页面变化 */
    handleCurrentChange (newPage) {
        this.queryInfo.pagenum = newPage
        this.getClassList()
    },
    /* 重置表单数据 */
    addDialogclosed () {
        this.$refs.addFormRef.resetFields()
    },
    /* 添加班级 */
    addClass () {
        this.$refs.addFormRef.validate(async valid => {
            if (!valid) return
            const { data: res } = await this.$http.post('/classes', this.addForm)
            if (res.flag !== true) {
                return this.$message.error('添加失败')
            }
            this.$message.success('添加成功')
            this.addDialogVisible = false
            this.getClassList()
        })
    },
    /* 获取单个班级数据 */
    async showEditDialog (id) {
        const { data: res } = await this.$http.get('classes/' + id)
        if (res.flag !== true) {
            return this.$message.error('查询课程信息失败!请重新登录')
        }
        this.editForm = res.data
        this.editDialogVisible = true
    },
    /* 删除单个班级数据 */
    async removeClassById (id) {
        const confirmResult = await this.$confirm('此操作将永久删除该学校信息, 是否继续?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
        }).catch(err => err)

        if (confirmResult !== 'confirm') {
            return this.$message.info('已取消删除')
        }
        const { data: res } = await this.$http.delete('classes/' + id)
        if (res.flag !== true) {
            return this.$message.error('删除失败！')
        }
        this.$message.success('删除学校成功')
        this.getClassList()
    },
    /* 修改班级数据 */
    editClassInfo (id) {
        this.$refs.editFormRef.validate(async valid => {
          if (!valid) return
          const { data: res } = await this.$http.post('classes', this.editForm)
          if (res.flag !== true) {
              return this.$message.error('修改失败')
          }
          this.$message.success('修改成功')
          this.editDialogVisible = false
          this.getClassList()
        })
    }
  }
}
</script>

<style lang="less" scoped>

</style>
