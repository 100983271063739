<template>
  <div >
    <div class="container">注册图片上传</div>
<div >身份证正面图片(必填)</div>
<el-upload action ref="upload" :auto-upload="false" :on-change="((val)=>{fileChange(val,'src1')})" :file-list="fileList" :show-file-list="false">
                      <el-button type="primary">上传<i class="el-icon-upload el-icon--right"></i></el-button></el-upload>
                    <div>学信网截图(必填)</div>
                    <el-upload action ref="upload" :auto-upload="false" :on-change="((val)=>{fileChange(val,'src2')})" :file-list="fileList" :show-file-list="false">
                      <el-button type="primary">上传<i class="el-icon-upload el-icon--right"></i></el-button></el-upload>
                    <div>录取花名册(必填)</div>
                    <el-upload action ref="upload" :auto-upload="false" :on-change="((val)=>{fileChange(val,'src3')})" :file-list="fileList" :show-file-list="false">
                      <el-button type="primary">上传<i class="el-icon-upload el-icon--right"></i></el-button></el-upload>
                    <div>曾经院校1(有则填)</div>
                    <el-upload action ref="upload" :auto-upload="false" :on-change="((val)=>{fileChange(val,'src4')})" :file-list="fileList" :show-file-list="false">
                      <el-button type="primary">上传<i class="el-icon-upload el-icon--right"></i></el-button></el-upload>
                    <div>曾经院校2(有则填)</div>
                    <el-upload action ref="upload" :auto-upload="false" :on-change="((val)=>{fileChange(val,'src5')})" :file-list="fileList" :show-file-list="false">
                      <el-button type="primary">上传<i class="el-icon-upload el-icon--right"></i></el-button></el-upload>
                    <div>曾经院校3(有则填)</div>
                    <el-upload action ref="upload" :auto-upload="false" :on-change="((val)=>{fileChange(val,'src6')})" :file-list="fileList" :show-file-list="false">
                      <el-button type="primary">上传<i class="el-icon-upload el-icon--right"></i></el-button></el-upload>
                    <el-button @click="handleClick" class="container">点击提交</el-button>

  </div>
</template>
 
<script>
export default {
  methods: {
     async handleClick() {
        
          this.$message.success('提交成功')
      

    },
    async getSchoolList () {
        const { data: res } = await this.$http.get('classes')
        if (res.flag !== true) {
            return this.$message.error('获取数据失败！请重新登录')
        }
        this.schoolList = res.data
    },
    fileChange(file,src) {
        
        this.projectImg = file.raw
        console.log(this.projectImg)
        const typeArr = ['image/png', 'image/gif', 'image/jpeg', 'image/jpg'];
        const isJPG = typeArr.indexOf(file.raw.type) !== -1;
        // image/png, image/jpeg, image/gif, image/jpg
        const isLt3M = file.size / 1024 / 1024 < 10
        if (!isJPG) {
          this.$message.error('只能是jpg格式!');
          this.$refs.upload.clearFiles();
          this.files = null;
          return;
        }
        if (!isLt3M) {
          this.$message.error('上传图片大小不能超过 10MB!');
          this.$refs.upload.clearFiles();
          this.files = null;
          return;
        }
        this.files = file.raw;
        const formData = new FormData();
        formData.append('projectImg', this.files);
        formData.append('id',this.id);
        formData.append('src',src);
  
        let config={
          headers: {
            "Content-Type": "multipart/form-data"
          },
  
        }
        this.$http.post("/pictures",formData,config).then(res=>{
  
          if(res.data.flag == true){
            this.$message.success('上传成功')
            location.reload()
          }else{
            this.$message.error('上传失败')
          }
        })
      },
  },
  created () {

    this.getSchoolList()
    this.id=window.sessionStorage.getItem('id')
  },
  data () {
    return {
        id:'',
        fileList:[],
        schoolList:[],
        addform:{
            'name':'',
            'sex':'',
            'phone':'',
            'identitycard':'',
            'school':'',
            'code':'',
            'education':'',
            'history1':'',
            'history2':'',
            'history3':'',

        }
    }
  }
}
</script>
<style lang="less" scoped>
  .container {
    margin-left:40%;
font-size: 18px;
  justify-content: center;

}

</style>