<template>
  <div >
    <div class="container">注册信息填写</div>
<div class="info">姓名</div>
                    <el-input v-model="addform.name"></el-input>
                    <div>性别(必填)</div>

                    <el-select v-model="addform.sex" placeholder="请选择">
                            <el-option v-for="item in option1" :key="item.value" :label="item.label" :value="item.value">
                            </el-option>
                        </el-select>
                    <div>手机号(必填)</div>
                    <el-input v-model="addform.phone"></el-input>
                    <div>证件号码(必填)</div>
                    <el-input v-model="addform.identitycard"></el-input>
                    <div>学校(必填)</div>
                    <el-select v-model="addform.school" placeholder="请选择">
                            <el-option v-for="item in schoolList" :key="item.school" :label="item.school" :value="item.school">
                            </el-option>
                        </el-select>
                    <div>学校注册码(必填)</div>
                    <el-input v-model="addform.code"></el-input>
                    <div>最高学历(必填)</div>
                    <el-select v-model="addform.education" placeholder="请选择">
                            <el-option v-for="item in option3" :key="item.value" :label="item.label" :value="item.value">
                            </el-option>
                        </el-select>
                    <div>曾经院校1(有则填)</div>
                    <el-input v-model="addform.history1"></el-input>
                    <div>曾经院校2(有则填)</div>
                    <el-input v-model="addform.history2"></el-input>
                    <div>曾经院校3(有则填)</div>
                    <el-input v-model="addform.history3"></el-input>
                    <el-button @click="handleClick" class="container">点击提交</el-button>

  </div>
</template>
 
<script>
export default {
  methods: {
     async handleClick() {
        if(this.addform.name=='' || this.addform.sex=='' ||this.addform.phone=='' ||this.addform.identitycard=='' ||this.addform.school=='' ||this.addform.code=='' ||this.addform.education=='' ){
            this.$message.error('请完善信息');
        } else{
            const { data: res } =await this.$http.post('/coachs', this.addform)

            if (res.flag !== true) {
                return this.$message.error('注册码不正确')
            }
            window.sessionStorage.setItem('id',res.data.id)
            this.$router.push('/uploadPicture')
        }

    },
    async getSchoolList () {
        const { data: res } = await this.$http.get('classes')
        if (res.flag !== true) {
            return this.$message.error('获取数据失败！请重新登录')
        }
        this.schoolList = res.data
    },
  },
  created () {

    this.getSchoolList()
  },
  data () {
    return {
        option1: [{ value: '男', label: '男' }, { value: '女', label: '女' }],
        option3: [{ value: '专科', label: '专科' }, { value: '本科', label: '本科' }, { value: '硕士', label: '硕士' }, { value: '博士', label: '博士' }],
        schoolList:[],
        addform:{
            'name':'',
            'sex':'',
            'phone':'',
            'identitycard':'',
            'school':'',
            'code':'',
            'education':'',
            'history1':'',
            'history2':'',
            'history3':'',

        }
    }
  }
}
</script>
<style lang="less" scoped>
  .container {
    margin-left:40%;
font-size: 18px;
  justify-content: center;

}

</style>