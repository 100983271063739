<template>
    <div>
        <!--导航-->
        <el-breadcrumb separator="/">
            <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>注册运动员信息</el-breadcrumb-item>
        </el-breadcrumb>
        <!--卡片-->
        <el-card class="box-card">
            <!--搜索-->
            <el-row :gutter="12">
                <el-col :span="4">
                    <el-select v-model="queryInfo.education" placeholder="请选择学历" clearable>
                        <el-option v-for="item in option3" :key="item.label" :label="item.value" :value="item.value">
                        </el-option>
                    </el-select>
                </el-col>

                <el-col :span="4">
                    <el-select v-model="queryInfo.status1" placeholder="请选择审核状态" clearable>
                        <el-option v-for="item in option2" :key="item.label" :label="item.value" :value="item.value">
                        </el-option>
                    </el-select>
                </el-col>

                <el-col :span="4">
                    <el-input placeholder="请输入姓名" v-model="queryInfo.name">
                        <el-button slot="append" icon="el-icon-search" @click="getCoachList()"></el-button>
                    </el-input>
                </el-col>
 

            </el-row>
            <!--用户列表-->
            <el-table :data="coachList" border stripe id="tabid">
                <el-table-column type="index"></el-table-column>
                <el-table-column label="姓名" prop="name" width="60px"></el-table-column>
                <el-table-column label="性别" prop="sex" width="60px"></el-table-column>
                <el-table-column label="身份证号" prop="identitycard" width="160px"></el-table-column>
                <el-table-column label="手机" prop="phone" width="100px"></el-table-column>
                <el-table-column label="当前院校" prop="school" width="100px"></el-table-column>
                <el-table-column label="学校邀请码" prop="code" width="100px"></el-table-column>
                <el-table-column label="当前学历" prop="education" width="80px"></el-table-column>
                <el-table-column label="历史院校1" prop="history1" width="100px"></el-table-column>
                <el-table-column label="历史院校2" prop="history2" width="100px"></el-table-column>
                <el-table-column label="历史院校3" prop="history3" width="100px"></el-table-column>
                <el-table-column label="审核" prop="status1" width="60px"></el-table-column>
   
              <el-table-column label="查看证明" width="80px">
                  <template slot-scope="scope">
                    <el-tooltip effect="dark" content="查看图片" placement="top" :enterable="false">
                          <el-button type="primary" icon="el-icon-view" size="mini"  @click="showviewDialog(scope.row.src)"></el-button>
                      </el-tooltip>

                      </template>
              </el-table-column>
                <el-table-column label="操作" >
                    <template slot-scope="scope">
                        <el-tooltip effect="dark" content="编辑" placement="top" :enterable="false">
                            <el-button type="primary" icon="el-icon-edit" size="mini" @click="showEditDialog(scope.row.id)"></el-button>
                        </el-tooltip>
                        <el-tooltip effect="dark" content="删除" placement="top" :enterable="false">
                            <el-button type="danger" icon="el-icon-delete" size="mini" @click="removeCoachById(scope.row.id)"></el-button>
                        </el-tooltip>
                        </template>
                </el-table-column>
            </el-table>
            <!--分页-->
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="queryInfo.pagenum"
            :page-sizes="[10, 50, 100, 1000]" :page-size="queryInfo.pagesize" layout="total, sizes, prev, pager, next, jumper" :total="this.total">
            </el-pagination>
        </el-card>
              <!--查看对话框-->
              <el-dialog title="查看图片" :visible.sync="viewDialogVisible" width="60%" :close-on-click-modal="false" >
          <!--修改内容-->
          <div>身份证正面图片(必填)</div>
          <img :src="src1">
          <div>学信网截图(必填)</div>
          <img :src="src2">
          <div>录取花名册(必填)</div>
          <img :src="src3">
          <div>曾经院校1(有则填)</div>
          <img :src="src4">
          <div>曾经院校2(有则填)</div>
          <img :src="src5">
          <div>曾经院校3(有则填)</div>
          <img :src="src6">
          <!--底部-->
          <span slot="footer" class = "dialog-footer">
              <el-button @click="viewDialogVisible = false">关闭</el-button>

          </span>
      </el-dialog>
        
        <!--修改对话框-->
        <el-dialog title="修改信息" :visible.sync="editDialogVisible" width="60%" :close-on-click-modal="false" >
            <!--修改内容-->
            <el-form :model="editForm" :rules="addFormRules" ref="editFormRef" label-width="100px">
                <el-form-item label="姓名" prop="name">
                    <el-input v-model="editForm.name"></el-input>
                </el-form-item>
                <el-form-item label="性别" prop="sex">
                    <template>
                        <el-select v-model="editForm.sex" placeholder="请选择">
                            <el-option v-for="item in option1" :key="item.value" :label="item.label" :value="item.value">
                            </el-option>
                        </el-select>
                    </template>
                </el-form-item>
                <el-form-item label="身份证号" prop="identitycard">
                    <el-input v-model="editForm.identitycard"></el-input>
                </el-form-item>
                <el-form-item label="手机" prop="phone">
                    <el-input v-model="editForm.phone"></el-input>
                </el-form-item>
                <el-form-item label="当前院校" prop="school">
                    <el-select v-model="editForm.school" placeholder="请选择">
                            <el-option v-for="item in schoolList" :key="item.school" :label="item.school" :value="item.school">
                            </el-option>
                        </el-select>
                </el-form-item>
                <el-form-item label="学校邀请码" prop="code">
                    <el-select v-model="editForm.code" placeholder="请选择">
                            <el-option v-for="item in schoolList" :key="item.code" :label="item.code" :value="item.code">
                            </el-option>
                        </el-select>
                </el-form-item>
                <el-form-item label="当前学历" prop="education">
                    <template>
                        <el-select v-model="editForm.education" placeholder="请选择">
                            <el-option v-for="item in option3" :key="item.value" :label="item.label" :value="item.value">
                            </el-option>
                        </el-select>
                    </template>
                </el-form-item>
                <el-form-item label="历史院校1" prop="history1">
                    <el-input v-model="editForm.history1"></el-input>
                </el-form-item>
                <el-form-item label="历史院校2" prop="history2">
                    <el-input v-model="editForm.history2"></el-input>
                </el-form-item>
                <el-form-item label="历史院校3" prop="history3">
                    <el-input v-model="editForm.history3"></el-input>
                </el-form-item>
                <el-form-item label="审核1" prop="status1">
                    <template>
                        <el-select v-model="editForm.status1" placeholder="请选择">
                            <el-option v-for="item in option2" :key="item.value" :label="item.label" :value="item.value">
                            </el-option>
                        </el-select>
                    </template>
                </el-form-item>

            </el-form>
            <!--底部-->
            <span slot="footer" class = "dialog-footer">
                <el-button @click="editDialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="editCoachInfo">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import global from '../global/global.vue'
import FileSaver from 'file-saver'
import XLSX from 'xlsx'
export default {

  data () {
    return {
        

        url:global.httpUrl,
    src:'',
      /* 性别状态选择 */
      option1: [{ value: '男', label: '男' }, { value: '女', label: '女' }],
      option2: [{ value: '审核中', label: '审核中' }, { value: '审核不通过', label: '审核不通过' }, { value: '审核通过', label: '审核通过' }],
      option3: [{ value: '专科', label: '专科' }, { value: '本科', label: '本科' }, { value: '硕士', label: '硕士' }, { value: '博士', label: '博士' }],
      /* 分页请求数据 */
      queryInfo: {
        query: '',
        pagenum: 1,
        pagesize: 10,
        name:'',
        status1:'',
        status2:'',
        school:'',
        education:''
      },
      /* 教练列表 */
      
      coachList: [],
      schoolList:[],
      /* 数据总数 */
      total: 0,
      /* 控制添加修改显示 */
      addDialogVisible: false,
      editDialogVisible: false,
      editForm: {},
      /* 添加教练表单 */
      addForm: {

      },
      viewDialogVisible:false,
      /* 添加修改规则 */
      addFormRules: {
        name: [
          { required: true, message: '请输入姓名', trigger: 'blur' }
        ],
        sex: [
          { required: true, message: '请选择性别', trigger: 'blur' }
        ],
        identitycard: [
          { required: true, message: '请输入身份证号', trigger: 'blur' },
        ],
        phone: [
          { required: true, message: '请输入手机号码', trigger: 'blur' },
          { min: 11, max: 11, message: '请输入11位电话' }
        ],
        school: [
          { required: true, message: '请输入院校', trigger: 'blur' }
        ],
        code: [
          { required: true, message: '请输入学校邀请码', trigger: 'blur' }
        ],
        education: [
          { required: true, message: '请选择学历', trigger: 'blur' }
        ],
        status1: [
          { required: true, message: '请选择状态', trigger: 'blur' }
        ],

      }
    }
  },

  created () {
    this.queryInfo.school=window.sessionStorage.getItem('school')
    this.getCoachList()



  },
  methods: {
    
   
    showviewDialog (src1,src2,src3,src4,src5,src6) {
      this.src1 = this.url + src1
      this.src2 = this.url + src2
      this.src3 = this.url + src3
      this.src4 = this.url + src4
      this.src5 = this.url + src5
      this.src6 = this.url + src6

  
      this.viewDialogVisible = true
  },
    fileChange(file,id) {
      console.log(id)
      this.projectImg = file.raw
      console.log(this.projectImg)
      const typeArr = ['image/png', 'image/gif', 'image/jpeg', 'image/jpg'];
      const isJPG = typeArr.indexOf(file.raw.type) !== -1;
      // image/png, image/jpeg, image/gif, image/jpg
      const isLt3M = file.size / 1024 / 1024 < 10
      if (!isJPG) {
        this.$message.error('只能是图片!');
        this.$refs.upload.clearFiles();
        this.files = null;
        return;
      }
      if (!isLt3M) {
        this.$message.error('上传图片大小不能超过 5MB!');
        this.$refs.upload.clearFiles();
        this.files = null;
        return;
      }
      this.files = file.raw;
      const formData = new FormData();
      formData.append('projectImg', this.files);
      formData.append('id',id);

      let config={
        headers: {
          "Content-Type": "multipart/form-data"
        },

      }
      this.$http.post("/uploadcoachs",formData,config).then(res=>{

        if(res.data.flag == true){
          this.$message.success('上传成功')
          location.reload()
        }else{
          this.$message.error('上传失败')
        }
      })
    },
    /* 获取教练数据 */
    async getCoachList () {
        const { data: res } = await this.$http.get('coachs/' + this.queryInfo.pagenum + '/' + this.queryInfo.pagesize + '?name=' + this.queryInfo.name + '&status1=' + this.queryInfo.status1+ '&status2=' + this.queryInfo.status2+ '&school=' + this.queryInfo.school+ '&education=' + this.queryInfo.education)
        if (res.flag !== true) {
            return this.$message.error('获取数据失败！请重新登录')
        }
        this.$message.success('获取数据成功！')
        this.coachList = res.data
        this.total = parseInt(res.msg)
    },
    /* 处理页面大小 */
    handleSizeChange (newSize) {
        this.queryInfo.pagesize = newSize
        this.getCoachList()
    },
    /* 处理页面变化 */
    handleCurrentChange (newPage) {
        this.queryInfo.pagenum = newPage
        this.getCoachList()
    },
    /* 重置表单数据 */
    addDialogclosed () {
        this.$refs.addFormRef.resetFields()
    },
    /* 添加教练 */
    addCoach () {
        this.$refs.addFormRef.validate(async valid => {
            if (!valid) return
                const { data: res } = await this.$http.post('/coachs', this.addForm)
            if (res.flag !== true) {
                return this.$message.error('添加失败')
            }
            this.$message.success('添加成功')
            this.addDialogVisible = false
            this.getCoachList()
        })
    },
    /* 获取单个教练数据 */
    async showEditDialog (id) {
        const { data: res } = await this.$http.get('coachs/' + id)
        if (res.flag !== true) {
            return this.$message.error('查询教练信息失败!请重新登录')
        }
        this.editForm = res.data
        this.editDialogVisible = true
    },
    /* 删除单个教练数据 */
    async removeCoachById (id) {
        const confirmResult = await this.$confirm('此操作将永久删除该运动员信息, 是否继续?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
        }).catch(err => err)

        if (confirmResult !== 'confirm') {
            return this.$message.info('已取消删除')
        }
        const { data: res } = await this.$http.delete('coachs/' + id)
        if (res.flag !== true) {
            return this.$message.error('删除失败！')
        }
        this.$message.success('删除教练成功')
        this.getCoachList()
    },
    /* 修改教练数据 */
    editCoachInfo (id) {
        this.$refs.editFormRef.validate(async valid => {
            if (!valid) return
            console.log(this.editForm)
            const { data: res } = await this.$http.post('coachs', this.editForm)
            if (res.flag !== true) {
                return this.$message.error('修改失败')
            }
            this.$message.success('修改成功')
            this.editDialogVisible = false
            this.getCoachList()
        })
    }
  }
}
</script>

<style lang="less" scoped>

</style>
