import Vue from 'vue'
import Inform from '../components/Upload.vue'
import VueRouter from 'vue-router'
import Login from '../components/user/Login.vue'
import Home from '../components/Home.vue'
import School from '../components/School.vue'
import Coach from '../components/edu/Coach.vue'
import Athlete from '../components/edu/Athlete.vue'
import Classcate from '../components/edu/Classcate.vue'
import UploadPicture from '../components/UploadPicture.vue'




Vue.use(VueRouter)
const router = new VueRouter({
  routes: [
    { path: '/', redirect: '/login' },
    { path: '/upload', component: Inform },
    { path: '/uploadPicture', component: UploadPicture },
    { path: '/login', component: Login },
    {
      path: '/home',
      component: Home,
      children: [
{ path: '/edu/coach', component: Coach }, { path: '/edu/classcate', component: Classcate },
      ]
    },
    {
      path: '/school',
      component: School,

      children: [
{ path: '/athlete', component: Athlete }
      ]  
    },
  ]
})
router.beforeEach((to, from, next) => {
  if (to.path === '/login' || to.path === '/upload' || to.path === '/uploadPicture') return next()
  const tokenStr = window.sessionStorage.getItem('token')
  if (!tokenStr) return next('/login')
  next()
})

export default router
