<template>
<div class="login-container">
    <div class="login_box">
        <!--头像区域-->
        <div class="avatar_box">
          湖北省运动员注册系统
        </div>
        <!--登陆表单区域-->
        <el-form ref="loginFormRef" :model="loginForm" :rules="loginFormRules" label-width="0px" class="login_form">
            <!--用户名-->
            <el-form-item prop="username">
                <el-input v-model="loginForm.username" prefix-icon="el-icon-user-solid"></el-input>
            </el-form-item>
            <!--密码-->
            <el-form-item prop="password">
                <el-input v-model="loginForm.password" type="password" prefix-icon="el-icon-lock"></el-input>
            </el-form-item>
            <!--按钮区域-->
            <el-form-item class="btns">
                <el-button type="primary" @click="login">登陆</el-button>
                <el-button type="info" @click="resetLoginForm">重置</el-button>
            </el-form-item>
        </el-form>
    </div>
</div>
</template>

<script>
export default {
  data () {
    return {
      /* 登录数据 */
      loginForm: {
        username: '',
        password: ''
      },
      /* 登录表单验证 */
      loginFormRules: {
        username: [
          { required: true, message: '请输入用户名', trigger: 'blur' },

        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },

        ]
      }
    }
  },
  methods: {
    /* 重置表单 */
    resetLoginForm () {
      this.$refs.loginFormRef.resetFields()
    },
    /* 登录 */
    login () {
        this.$refs.loginFormRef.validate(async valid => {
            if (!valid) return
            const { data: res } = await this.$http.post('login', this.loginForm)
            if (res.flag !== true) return this.$message.error('登录失败! ')
            this.$message.success('登录成功!')

            window.sessionStorage.setItem('token', res.msg)
      
            if (res.data == 'admin'){
              
            this.$router.push('/home')
 
            }else{
            window.sessionStorage.setItem('school',res.data)
            this.$router.push('/school')
            }
        })
    }
  }
}
</script>

<style lang="less" scoped>
.login-container {
    background-color: #2b4b6b;
    height: 100%;
    .avatar_box {
        height: 150px;
        width: 200px;
        position: absolute;
        left: 25%;
        top:15%;
font-size:20px;
        background-color: white;




    }
}
.login_form {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 0 20px;
    box-sizing: border-box;
}
.login_box {
    background-color: #fff;
    height: 300px;
    width: 450px;
    border-radius: 3px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
}
  .btns {
      /*尾部对齐*/
      display: flex;
      justify-content: flex-end;
  }
</style>
